import axios from 'axios'
import { GeneType, Breed, Upload, RemoveUpload, Dna } from './../api'
import { notification } from 'antd'

import { getToken } from '../../../request'
import { removeFileItem } from 'antd/lib/upload/utils'

export const AddGeneType = async (inputData) => {
  await axios
    .post(GeneType(), inputData , getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}

export const AddDna = async (inputData) => {
  await axios
    .post(Dna(), inputData , getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}

export const DownloadFile = async (inputData,breedName) => {
  await axios
    .get(
      Dna(),
      { params: inputData },
      { responseType: 'blob', ...getToken() } )
    .then(function ({ data }) {
      if (data.error) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      } else {
        notification.success({
          message: 'Success',
          description: data.message,
        })

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${breedName}.fas`)
        document.body.appendChild(link)
        link.click()

        return
      }
    })
}
export const FetchFile = (breedId) => {
  return new Promise(async (resolve) => {
    await axios
      .get(Dna() + '/' + breedId , getToken())
      .then(function ({ data }) {
        if (data.error) {
          notification.error({
            message: 'Failed',
            description: data.message,
          })
        } else {
          notification.success({
            message: 'Success',
            description: data.message,
          })

          return resolve(data.data)
        }
      })
  })
}

export const AddBreed = async (inputData) => {
  await axios
    .post(Breed(), inputData , getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}
export const ListBreed = async (inputData) => {
  return await axios
    .get(Breed(),{params: {...inputData}, ...getToken()})
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data.data
    })
    .catch(function (error) {})
}
export const ListGeneType = async (inputData) => {
  return await axios
    .get(GeneType(), {params: {...inputData}, ...getToken()})
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data.data
    })
    .catch(function (error) {})
}

export const upload = async (file, fileList) => {
  const formData = new FormData()
  formData.append('file', file)

  return axios
    .post(Upload(), formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => {
      notification.success(`file uploaded successfully`)

      //upload only one file
      if (fileList.length > 0) removeFileUpload(fileList)
      return res.data
    })
    .catch((e) => {
      console.log(e)
      notification.error(`file upload failed.`)
    })
}

const removeFileUpload = async (fileList) => {
  for (var i = 0; i < fileList.length; i++) {
    await removeUpload(fileList[i])
      .then(() => {
        // var start = fileList.indexOf(fileList[])
        fileList.splice(i, 1)

        return
      })
      .catch((e) => {
        console.log(e)
        notification.error(`Something wrong.`)
      })
  }
}

export const removeUpload = async (file) => {
  axios
    .post(RemoveUpload(), {
      //fieldName = file
      key: file.file,
    },getToken())
    .then(() => {
      return
    })
    .catch((e) => {
      console.log(e)
      notification.error(`Something wrong.`)
    })
}
