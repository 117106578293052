import React, {useEffect, useState, useRef} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Input,
    Card,
    Popconfirm,
    Row,
    Col,
    Tooltip,
    Drawer,
    Tag,
    Select,
    AutoComplete, notification
} from 'antd'
import { FetchFileByType, FetchFile, ListBreed, ListGeneType, deleteDnaFile } from '../actions'
import {TableComp} from 'sz-react-utils'
import Request from '../../../request'
import moment from 'moment'

const {Option} = Select

export const List = () => {
    const table = useRef(null)
    const {TextArea} = Input
    const [dnaCount, setDnaCount] = useState(0)
    const [deleteLoad, setDLoad] = useState(0)
    const [breedId, setBreedId] = useState()
    const [userList, setUserList] = useState(false)
    const [selectedBreed, setSelectedBreed] = useState()
    const [selectedGene, setSelectedGene] = useState()
    const [selectedBreedType, setSelectedBreedType] = useState()
    const [selectedSpeciesType, setSelectedSpeciesType] = useState()
    const [listBreed, setlistBreed] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [listGeneType, setlistGeneType] = useState([])
    const [dna, setDna] = useState([])
    const [count, setCount] = useState([])
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const fetchData=async(breedId)=>{

        delete breedId.dnaContent;
        delete breedId.createdAt;
        delete breedId.updatedAt;
        setDna(null)
        setDrawerVisible(true)
        if (!breedId) return
        const data = await FetchFile(breedId)
        setDna(data)
        setDnaCount(data.total)
    }

    useEffect(async () => {
        // let tmplistGeneType = (await ListGeneType()) || []
        let tmpuserList =
            (await Request.userList({
                userType: 'User',
                regExFilters: [],
                status: 'Active',
            })) || []

        // setlistGeneType(tmplistGeneType)
        setUserList(tmpuserList)
    }, [])

    useEffect(async () => {
        if (!selectedSpeciesType) return

        let tmplistBreed =
            (await ListBreed({speciesType: selectedSpeciesType})) || []
        setlistBreed(tmplistBreed)
    }, [selectedSpeciesType])

    useEffect(async () => {
        if (!selectedSpeciesType) return

        let tmplistGeneType = (await ListGeneType({speciesType: selectedSpeciesType})) || []
        setlistGeneType(tmplistGeneType)
    }, [selectedSpeciesType])

    useEffect(() => {
        table.current.reload()
    }, [
        selectedSpeciesType,
        selectedBreed,
        selectedBreedType,
        selectedGene,
        selectedUser,
    ])

    const removeFilter = async () => {
        setSelectedBreedType()
        setSelectedBreed()
        setSelectedGene()
        setSelectedUser()
        setSelectedSpeciesType()
        setlistGeneType([])
        setlistBreed([])
        table.current.reload()
    }

    const apiRequest = (params) => {
        if (params.page) {
            setCurrentPage(params.page)
        }
        const currentUser = JSON.parse(localStorage.getItem('user'))

        return new Promise(async (resolve) => {
            let tt = await FetchFileByType({
                type: 'approved',
                userId: currentUser.userType === 'Admin' ? 0 : currentUser.id,
                speciesType: selectedSpeciesType,
                breedType: selectedBreedType,
                geneType: selectedGene,
                breed: selectedBreed,
                createdByUserId: selectedUser,
            })
            setCount(tt.total)
            resolve(tt)
            // this.setState({ count: tt.total })
            // resolve(tt)
        })
    }

    const findCount = (record) => {
        let obj = count.find((ele) => ele.breed === record.breedId)
        return obj ? obj.count : ''
    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 100,
            key: 'sno',
            render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
        },
        {
            title: 'Species Type',
            dataIndex: 'speciesType',
            key: 'speciesType',
            // searchTextName: 'speciesType',
        },
        {
            title: 'DNA Type',
            dataIndex: 'breedType',
            key: 'breedType',
            searchTextName: 'breedType',
        },
        {
            title: 'Gene Type',
            dataIndex: 'geneType',
            key: 'geneType',
            searchTextName: 'geneType',
        },
        {
            title: 'Breed',
            dataIndex: 'breed',
            key: 'breed',
        },
        {
            title: 'Created By',
            dataIndex: 'createdByUserId',
            key: 'createdByUserId',
            searchTextName: 'createdByUserId',
        },
        {
            title: 'Created At',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (text, record) => {
                return <Tag>{text ? moment(text).fromNow() : ''}</Tag>
            },
        },
        {
            key: 'totalDna',
            title: 'Total DNAs',
            width: 120,
            render: (text, record, key) => {
                return (
                    <>
                        <Tooltip title="View File">
                            <Button
                                type="warning"
                                size="small"
                                style={{marginTop: '6px'}}
                                onClick={() => {
                                    fetchData(record)
                                }}>
                                {findCount(record)}
                            </Button>
                        </Tooltip>
                    </>
                )
            },
        },
    ]

    const deleteFile = async (arr) => {

        console.log(arr)


        const currentUser = JSON.parse(localStorage.getItem('user'))
        if(currentUser.userType === 'Admin') {

            setDLoad(true)
            await deleteDnaFile({ arr })
            setDLoad(false)

            setDrawerVisible(false);
            setTimeout(() => {
                window.location.reload()

            }, 1000)
        }else{
            notification.warning({
                message: 'You cannot delete.',
            })
        }
    }

    return (
        <>
            <PageHeaderWrapper title={`All Approved File (${count.length})`}>
                <Card style={{marginBottom: '20px'}}>
                    <h1>Choose Filters :</h1>
                    <Row type="flex" justify={'space-around'}>
                        <Col span={4}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{width: '100%'}}
                                value={selectedSpeciesType}
                                placeholder={'Search Species'}
                                onChange={(id) => {
                                    setSelectedSpeciesType(id)
                                    setSelectedBreed('')
                                    setSelectedGene('')
                                    if (!id) {
                                        setlistGeneType([])
                                        setlistBreed([])
                                    }
                                }}>
                                <Option value={'Camel'}>{'CAMEL'}</Option>
                                <Option value={'Goat'}>{'GOAT'}</Option>
                                <Option value={'Sheep'}>{'SHEEP'}</Option>
                            </Select>
                        </Col>

                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedBreedType}
                                // onSelect={this.onContactSelect}
                                // onSearch={this.searchContact}
                                onChange={(val) => {
                                    setSelectedBreedType(val)
                                }}
                                placeholder={'Search DNA Type'}
                                style={{width: '100%'}}>
                                <Option value={'Allelic'}>{'ALLELIC'}</Option>
                                <Option value={'Mixed'}>{'MIXED'}</Option>
                            </Select>
                        </Col>

                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedGene}
                                // onSelect={this.onContactSelect}
                                // onSearch={this.searchContact}
                                onChange={(val) => {
                                    setSelectedGene(val)
                                }}
                                placeholder={'Search Gene'}
                                style={{width: '100%'}}>
                                {listGeneType &&
                                listGeneType.map((val, item) => (
                                    <Option key={val.id} value={val.id}>
                                        <>
                                            <div>{`${val.name}`}</div>
                                        </>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedBreed}
                                onChange={(val) => {
                                    setSelectedBreed(val)
                                }}
                                placeholder={'Search Breed'}
                                style={{width: '100%'}}>
                                {listBreed &&
                                listBreed.map((val, item) => (
                                    <Option key={val.id} value={val.id}>
                                        <>
                                            <div>{`${val.name}`}</div>
                                        </>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        {JSON.parse(localStorage.getItem('user')).userType === 'Admin' ? (
                            <Col span={4}>
                                <Select
                                    value={selectedUser}
                                    onChange={(val) => {
                                        setSelectedUser(val)
                                    }}
                                    placeholder={'Search User'}
                                    style={{width: '100%'}}>
                                    {userList &&
                                    userList.data &&
                                    userList.data.map((val, item) => (
                                        <Option key={val.id} value={val.id}>
                                            <>
                                                <div>{`${val.name}`}</div>
                                            </>
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        ) : (
                            ''
                        )}

                        <Col>
                            <Button type="default" onClick={removeFilter}>
                                Clear Filters
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card bordered={true}>
                    <Row>
                        <Col>
                            <TableComp
                                bordered={true}
                                ref={table}
                                columns={columns}
                                apiRequest={apiRequest}
                                rowKey={(x) => x.id}
                                pagination={{
                                    defaultPageSize: 10,
                                    pageSizeOptions: ['10', '25', '50', '100'],
                                    showSizeChanger: true,
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
                <Drawer
                    title={`All DNAs (${dnaCount})`}
                    placement="right"
                    width={'50%'}
                    closable={true}
                    onClose={() => {
                        setDrawerVisible(false)
                    }}
                    // visible={this.state.drawerVisible}>
                    visible={drawerVisible}>
                    <Card bordered={true}>
                        <div>
                            <Row>
                                <Col span={16}>
                                    {dna &&
                                    `${dna.speciesType} --> ${dna.breedType} --> ${dna.geneType} --> ${dna.breed}`}
                                </Col>

                                <Col span={8}>
                                    <Tooltip title="Delete File">
                                        <Popconfirm
                                          title="Are you sure to Delete this File?"
                                          onConfirm={async () => {


                                              await deleteFile(dna.dnaIds)

                                          }}
                                          onCancel={() => {
                                              console.log()
                                          }}
                                          okText="Yes"
                                          cancelText="No">
                                            <Button
                                              // shape="circle"
                                              size="small"
                                              type={'danger'}

                                              loading={deleteLoad}
                                            >Delete DNAs</Button>
                                        </Popconfirm>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>
                        <br/>

                        {dna && (
                            <Col span={24}>
                                <TextArea rows={50} defaultValue={dna.dnaContent}/>
                            </Col>
                        )}
                    </Card>
                </Drawer>
            </PageHeaderWrapper>
        </>
    )
}

const WrappedAnnouncement = Form.create()(List)

export default WrappedAnnouncement
