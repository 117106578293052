import axios from 'axios'
import { GeneType, Breed, getAllBreed, getAllDnaType } from '../../setting/api'
import { notification } from 'antd'
import { getToken } from '../../../request'

export const AddGeneType = async (inputData) => {
  await axios
    .post(GeneType(), inputData , getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}
export const AddBreed = async (inputData) => {
  await axios
    .post(Breed(), inputData, getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}


export const UpdateBreed = async (inputData) => {
  await axios
    .put(Breed()+inputData.id, inputData, getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}

export const UpdateGeneType = async (inputData) => {
  await axios
    .put(GeneType()+inputData.id, inputData, getToken())
    .then(function ({ data }) {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        })
        return
      } else {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
      }
    })
    .catch(function (error) {})
}
export const ListBreed = async (inputData) => {
  return await axios
    .get(Breed(),{params: {...inputData}, ...getToken()})
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data
    })
    .catch(function (error) {})
}
export const ListGeneType = async (inputData) => {
  return await axios
    .get(GeneType(), {params: {...inputData}, ...getToken()})
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data
    })
    .catch(function (error) {})
}

export const DeleteBreed = async (inputData) => {
  return await axios
    .delete(Breed() + inputData.id  , getToken())
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data
    })
    .catch(function (error) {})
}
export const DeleteGeneType = async (inputData) => {
  return await axios
    .delete(GeneType() + inputData.id  , getToken())
    .then(function ({ data }) {
      if (!data.success) {
        notification.error({
          message: 'Failed',
          description: data.message,
        })
        return []
      } else return data
    })
    .catch(function (error) {})
}