import React, {useState, useRef} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {Form, Button, Card, Tooltip, Popconfirm} from 'antd'
import {notification, Tabs} from 'antd/lib/index'
import Request, {API_URL} from '../../../request'
import {TableComp} from 'sz-react-utils-lite'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {AddBreed, ListBreed, DeleteBreed, UpdateBreed} from '../action'
import {useSelector, useDispatch} from 'react-redux'
import _ from 'lodash'
import {specArr} from '../../../settings'

const {TabPane} = Tabs

const BillingSetting = (props) => {
    const table = useRef(null)
    const [load, setLoad] = useState(false)
    const [activeTab, setActiveTab] = useState('2')
    const [editRecord, setEditRecord] = useState(null)
    const [count, setCount] = useState(0)

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        setLoad(true)
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                if (editRecord) {
                    valData.id = editRecord.id
                    await UpdateBreed(valData)
                } else {
                    await AddBreed(valData)
                    form.resetFields()
                }
                setLoad(false)
            } else {
                notification.warning({
                    message: 'Fill All Required Fields',
                })
            }
        })
    }

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await ListBreed(params)
      setCount(data.total)
            resolve(data)
        })
    }

    const callback = (key) => {
        if (key === '2') table.current.reload()
        setActiveTab(key)
    }

    const {
        form: {getFieldDecorator, setFieldsValue},
    } = props

    const subInputTypes1 = {
        fields: [
            {
                label: editRecord ? 'Edit Species Type' : 'Add Species Type',
                key: 'speciesType',
                type: 'select',
                options: specArr,
                onChange: (speciesType) => {
                    setFieldsValue({speciesType})
                },
            },
            {
                label: editRecord ? 'Edit Breed' : 'Add Breed',
                key: 'name',
                required: true,
            },

        ],
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
            md: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
            md: {span: 12},
        },
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            // searchTextName: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name',
        },
        {
            title: 'Species',
            dataIndex: 'speciesType',
            key: 'speciesType',
            searchTextName: 'speciesType',
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 150,
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Edit">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    setFieldsValue({
                                        name: record.name,
                                        speciesType: record.speciesType,
                                    })
                                    setEditRecord(record)
                                    setActiveTab('1')
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure to delete this ?"
                                onConfirm={async () => {
                                    // this.deleteProduct({_id: record._id})
                                    await DeleteBreed({id: record.id})
                                    table.current.reload()
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button shape="circle" size="small" icon="delete"/>
                            </Popconfirm>
                        </Tooltip>
                    </>
                )
            },
        },
    ]

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8},
        },
    }

    const operations = (
        <Button
            onClick={() => {
                window.location.reload()
            }}>
            Add Field
        </Button>
    )
    return (
        <PageHeaderWrapper
            title={
                activeTab === '2'
                    ? `All Breed (${count || 0})`
                    : editRecord
                    ? 'Edit Breed'
                    : 'Add Breed'
            }>
            <Tabs
                activeKey={activeTab}
                onChange={callback}
                tabBarExtraContent={editRecord ? operations : null}>
                <TabPane tab={editRecord ? 'Edit' : 'Add'} key="1" forceRender="true">
                    <Card>
                        <Form onSubmit={handleSubmit}>
                            <GetAllFormFields
                                apiurl={API_URL}
                                inputSchema={subInputTypes1}
                                formItemLayout={formItemLayout}
                                getFieldDecorator={getFieldDecorator}
                            />
                            <Form.Item {...submitFormLayout}>
                                <Button loader={load} type="primary" htmlType="submit">
                                    {load ? 'Wait..' : editRecord ? 'Update' : 'SAVE'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </TabPane>

                <TabPane tab="List" key="2">
                    <Card bordered={true}>
                        <TableComp
                            ref={table}
                            columns={columns}
                            apiRequest={apiRequest}
                            pagination={{
                                // ...this.state.pagination,
                                defaultPageSize: 10,
                                pageSizeOptions: ['10', '25', '50', '100'],
                                showSizeChanger: true,
                                // ...this.props.pagination,
                            }}
                        />
                    </Card>
                </TabPane>
            </Tabs>
        </PageHeaderWrapper>
    )
}

const WrappedAnnouncement = Form.create()(BillingSetting)

export default WrappedAnnouncement
