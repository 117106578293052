import axios from 'axios'
import { User } from './../api'

import { getToken } from '../../../request'

export const userList = async(data) => {
  return new Promise(next => {
      axios
          .get(User(), {params: {...data}, ...getToken()})
          .then(d => {
              next(d.data)
          })
          .catch(err => {


          })
  })
}
