import React, {useEffect, useState} from 'react'
import {Row, Col} from 'antd'
import {TableComp} from 'sz-react-utils'

import {Link} from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {userList} from '../actions/index'

import './index.css'
/*import sheep from './components/0055240.jpg'
import goat from './components/brcamel1.jpg'
import camel from './components/goat-3819397_960_720.jpg'*/
import ICAR_Logo from './assets/images/ICAR-Logo.png'
import mainLogo from './assets/images/main_logo.png'
import camelImg from './assets/images/camel.jpg'
import camelLogo from './assets/images/Camel-logo.png'
import bgImg from './assets/images/bg.png'
import dbImg from './assets/images/db.png'
import dbuploadImg from './assets/images/db-upload.png'
import goatImg from './assets/images/goat.jpg'
import listImg from './assets/images/list.png'
import ndrilogoImg from './assets/images/ndrilogo.png'
/*import ndrilogo1Img from './assets/images/ndrilogo1.png'
import ndrilogo111Img from './assets/images/ndrilogo111.png'*/
import personImg from './assets/images/person.png'
import serb_goiImg from './assets/images/serb_goi.png'
import sheepImg from './assets/images/sheep.jpg'


export const Landing = () => {

    const [arr, setArr] = useState([]);
    useEffect(async () => {

        apiRequest()
        /* AOS.init({
           duration: 2000,
         })*/

    }, [])

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Mobile',
            key: 'mobile',
            dataIndex: 'mobile',
        },

    ]

    const apiRequest = async () => {


        let params = {count: 100, results: 100}
        let {success, data} = await userList({
            ...params, regExFilters: [],
            status: 'Active',
        })
        console.log({success, data})
        if (success) {
            setArr(data)
        }

    }


    return (
        <>
            <div className="nav_sec">
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-6 col-sm-12">
                            <div className="d-flex  align-items-center pt-2">
                                <img src={mainLogo} id="main_logo"/>
                            </div>
                        </div>

                        <div className="col-md-5 col-sm-12 text-sm-center">
                            <img src={ICAR_Logo} className="pt-2 pb-4 float-end"/>
                        </div>
                        <div className="col-md-1 col-sm-12 text-sm-center align-items.center pt-5">
                            <a className={'loginbtn'} href="/#/login/">LOGIN</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider">
                <div className="container">
                    <div className="text-center">
                        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={sheepImg}/>
                                </div>
                                <div className="carousel-item">
                                    <img src={goatImg}/>
                                </div>
                                <div className="carousel-item">
                                    <img src={camelImg}/>
                                </div>
                                <div className="slider_text"><h2>Objective:</h2>&nbsp;<h4 style={{color: 'white'}}>One
                                    platform for SNP variation in important MHC
                                    genes of indigenous small ruminants and camelids</h4></div>


                            </div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleFade"
                                    data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleFade"
                                    data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="funding_sec mt-3">
                <div className="container">
                    <h2 className="text-center mb-0">SPONSORED BY</h2>
                    <div className="row d-flex">
                        <div className="col-md-2 col-sm-12">

                        </div>
                        <div className="col-md-10 col-sm-12">
                            <img src={serb_goiImg} className="pt-3 pe-3 float-start"/>
                            <p className="ps-4 mb-0 pt-3">SCIENCE AND ENGINEERING RESEARCH BOARD,<br/> DST GOI (Grants:
                                CRG/2018/003523
                                and EMR/2017/001252)</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="button_sec">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-4 col-sm-12" style={{marginBottom: '40px'}}>
                            <div className="card h-100 my-sm-2 text-center button_cards btn_1">
                                <span><img src={listImg}/></span>
                                <div className="card-body">
                                    <h5 className="card-title button_card_title">Contributors List</h5>
                                    <button type="button" className="btn">&rarr;</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12" style={{marginBottom: '40px'}}>
                            <div className="card h-100 my-sm-2 button_cards text-center  btn_2">
                                <span><img src={dbImg}/></span>
                                <div className="card-body">
                                    <h5 className="card-title button_card_title">View/Download Database</h5>
                                    <button type="button" className="btn" onClick={() => {
                                        window.location.href = "/#/download"
                                    }}>&rarr;</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12" style={{marginBottom: '40px'}}>
                            <div className="card h-100 my-sm-2 button_cards text-center  btn_3">
                                <span><img src={dbuploadImg}/></span>
                                <div className="card-body">
                                    <h5 className="card-title button_card_title">Upload Database</h5>

                                    <button type="button" className="btn mx-auto" onClick={() => {
                                        window.location.href = "/#/login"
                                    }}>&rarr;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="detail_sec mt-5 mb-5">
                <div className="">
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 d-flex">
                                        <img src={ICAR_Logo} height="170px"/>
                                        <p className="ps-4">ICAR - CENTRAL SHEEP & WOOL RESEARCH INSTITUTE KARNAL
                                            132001</p>
                                    </div>
                                    <div className="col-md-12 col-sm-12 d-flex" style={{paddingTop: '30px'}}>
                                        <img src={camelLogo} height="170px"/>
                                        <p className="ps-4">ICAR - NRC on Camel Bikaner Rajasthan</p>
                                    </div>
                                    <div className="col-md-12 col-sm-12 d-flex" style={{paddingTop: '30px'}}>
                                        <img src={ndrilogoImg} height="152px" id="img1"/>
                                        <p className="ps-3">ICAR - NATIONAL DAIRY RESEARCH INSTITUTE KARNAL 132001</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7 col-sm-12">
                            <div className="contact_sec container">
                                <h2>Contact Details</h2>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 d-flex pt-3 ps-0">
                                        <img src={personImg} height="90px" width="107px"
                                             style={{paddingLeft: '0px'}}/>
                                        <div className="contact_person">
                                            <h4 className="ps-3">DR. GOPAL R. GOWANE</h4>
                                            <span><i className="ps-3">Senior Scientist</i></span>
                                            <p><b className="ps-3">EMAIL : </b><a
                                                href="mailto:gopal.gowane@icar.org.in">gopal.gowane@icar.org.in</a></p>
                                        </div>
                                    </div>
                                    <div className="team_sec col-md-12 col-sm-12 ps-0">
                                        <h2>Core Team</h2>
                                        <div className="container ps-0">
                                            <div className="row pt-3">
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>DR. G.R. Gowane</h4>
                                                </div>
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>DR. S.S. Misra</h4>
                                                </div>
                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>DR.Ved Prakash</h4>
                                                </div>
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>MS. Priya Sharma</h4>
                                                </div>
                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>DR. Rajiv Kumar</h4>
                                                </div>
                                                <div className="col-md-6 col-sm-12 ps-0">
                                                    <h4>DR. Basanti Jyotsana, </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="imp_links">
                <div className="container">
                    <h2 style={{fontWeight: 'bold'}} className="pb-4 text-center">Important Links</h2>
                    <div className="row">
                        <div className="col-md-2 col-sm-12" style={{marginLeft: '13px'}}>
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href="http://imed.med.ucm.es/pvs/">PVS</a>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href="http://ncbi.nlm.nih.gov/">NCBI</a>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href="http://icar.gov.in/">ICAR</a>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href="http://ebi.ac.uk/ipd/mbc">EBI</a>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href={'../../../howToUse.pdf'}
                                                                                 download={'how to use.pdf'}>HOW TO USE?</a>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <span><i className="fas fa-globe-asia"></i></span><a target="_blank"
                                                                                 href={'https://bioinfo.ut.ee/primer3-0.4.0/'}>Primer3</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contib mb-5">
                <div className="container">
                    <h2 style={{fontWeight: 'bold'}} className="pb-4 text-center">Contributors List</h2>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th scope="col" style={{width: '7%'}}>S. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Email</th>
                                {/*   <th scope="col">Role</th>
            <th scope="col" style={{width:'9%'}}>Species</th>
            <th scope="col">Breed</th>
            <th scope="col">Gene</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                arr.map((v, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{v.name}</td>
                                            <td>{v.mobile}</td>
                                            <td>{v.email}</td>
                                            {/*<td>Scizers</td>
                  <td>MD</td>
                  <td>8</td>
                  <td>6</td>
                  <td>2</td>*/}
                                        </tr>
                                    )
                                })
                            }
                            {/*   <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Ram</td>
            <td>Scizers</td>
            <td>MD</td>
            <td>8</td>
            <td>6</td>
            <td>2</td>
          </tr>*/}
                            </tbody>
                        </table>
                        {/* <TableComp
          // ref={this.table}
          columns={columns}
          apiRequest={apiRequest}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: false,
          }}
        />*/}
                    </div>

                </div>
            </div>

        </>
    )

    /* return (
       <>
         <div className="hero centre">
           <nav>
             <a href="/login/">Login</a>
           </nav>
           <h1 style={{fontSize: "3rem"}}>Small Rumianants's MHC Database</h1>
           <h4 className="largetext">
             <b>Objective: </b> Development of database for MHC diversity in small
             ruminants of India
           </h4>
         </div>

         <Row type="flex" justify="space-around" data-aos="fade-up">
           <Col span={7} style={{ overflow: 'hidden' }}>
             <img src={sheep} width="100%" height="100%" alt="" />
           </Col>
           <Col span={7} style={{ overflow: 'hidden' }}>
             <img src={goat} width="100%" height="100%" alt="" />
           </Col>
           <Col span={7} style={{ overflow: 'hidden' }}>
             <img src={camel} width="100%" height="100%" alt="" />
           </Col>
         </Row>

         <div className="section1 largetext" data-aos="fade-up">
           Funding Agency : Science and Engineering Research Board ,DST GOI
         </div>

         <Row
           className="grid3"
           type="flex"
           justify="space-around"
           data-aos="fade-up">
           <Col className="item links" span={7}>
             <div className="button -regular center">
               <a href="#contributors">Contributors' List</a>
             </div>
           </Col>
           <Col className="item links" span={7}>
             <div className="button -regular center">
               <Link to="/download/">Download Data</Link>
             </div>
           </Col>
           <Col className="item links" span={7}>
             <div className="button -regular center">
               <Link to="/login">Upload Data</Link>
             </div>
           </Col>
         </Row>

         <div className="section1 centre largetext" data-aos="fade-up">
           ICAR - National Dairy Research Institute <br />
           ICAR - Central Sheep nad Wood Research Institute
         </div>

         <Row data-aos="fade-up">
           <Col span={24} className="centre">
             <h2 className="largeheader">Contact </h2>
           </Col>
           <Col className="centre largetext">
             Dr. Gopal R. Gowane ,<br /> Senior Scientist NDRI Karnal <br />
             Email: Gopal.gowane@icar.org.in
           </Col>
         </Row>

         <Row
           type="flex"
           justify="space-around"
           data-aos="fade-up"
           style={{ marginTop: '10%' }}>
           <Col span={24} className="centre">
             <h2 className="largeheader">Core </h2>
           </Col>
           <Col className="centre largetext">
             <Row>
               <Col>Mr. G.R. Gowane (PI) , DR. S.S. Misra (PS,AGB) - CoPI</Col>
             </Row>
             <Row>
               <Col>DR. Rajiv Kumar (SR. SCI) - Co Pi</Col>
             </Row>
             <Row>
               <Col>Ms Priya Sharma - SRF</Col>
             </Row>
           </Col>
         </Row>

         <Row
           type="flex"
           justify="space-around"
           data-aos="fade-up"
           style={{ marginTop: '10%' }}>
           <Col span={24} className="centre">
             <h2 className="largeheader">Important Links </h2>
           </Col>
           <Col span={16} className="centre largetext">
             <Col>
               <a href="">imedmeucm.es/pvs/</a>
             </Col>

             <Col>
               <a href="">ncbi.nlm.nih.gov</a>
             </Col>

             <Col>
               <a href="">icar.gov.in</a>
             </Col>

             <Col>
               <a href="">ebi.ac.uk/ipd/mhc</a>
             </Col>
           </Col>
         </Row>

         <h1 id="contributors" className="centre largeheader" style={{ marginTop: '10%' }}>
           Contributors:{' '}
         </h1>
         <br />
         <Row type="flex" justify="space-around">
           <Col md={15} md={20}>
             <TableComp
               // ref={this.table}
               columns={columns}
               apiRequest={apiRequest}
               pagination={{
                 defaultPageSize: 20,
                 showSizeChanger: false,
               }}
             />
           </Col>
         </Row>
       </>
     )*/
}
