import React, {useEffect, useState} from 'react'
import {Form, Button, Card, Icon} from 'antd'
import {
    DownloadDna,
    DownloadFile,
    ListBreed,
    ListGeneType,
} from './../../actions'
import {notification} from 'antd/lib/index'
import {API_URL} from '../../../../request'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {goBack} from 'connected-react-router'
import {connect} from 'react-redux'
import {specArr} from '../../../../settings'

const DownloadForm = (props) => {
    const [load, setLoad] = useState(false)
    const [listBreed, setlistBreed] = useState([])
    const [listGeneType, setlistGeneType] = useState([])
    const [selectedBreed, setSelectedBreed] = useState()

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        setLoad(true)
        form.validateFieldsAndScroll(async (err, valData) => {
            let tmp = ""
            if (!err) {
                listBreed.forEach((breed) => {
                    if (breed.id == valData.breed) tmp = breed.name
                })
                let resp = await DownloadFile(valData, tmp)
                // console.log(resp)
                form.resetFields()
                setLoad(false)
            } else {
                setLoad(false)
                notification.warning({
                    message: 'Fill All Required Fields',
                })
            }
        })
    }

    // useEffect(async () => {
    //     // getCompanyBillingType()
    //
    //     let listGeneType = await ListGeneType() || []
    //     setlistGeneType(listGeneType)
    // }, [])

    const {
        form: {getFieldDecorator, setFieldsValue},
    } = props

    const subInputTypes1 = {
        fields: [
            {
                key: 'speciesType',
                type: 'select',
                required: true,
                options: specArr,
                onChange: async (speciesType) => {
                    setFieldsValue({speciesType})
                    setFieldsValue({breed: null, geneType: null})
                    let listBreed = await ListBreed({speciesType}) || []
                    let listGeneType = (await ListGeneType({speciesType})) || []
                    setlistGeneType(listGeneType)
                    setlistBreed(listBreed)
                },
            },
            {
                label: 'DNA Type',
                key: 'breedType',
                type: 'select',
                required: true,
                options: ['ALLELIC', 'MIXED'],
                onChange: (breedType) => {
                    setFieldsValue({breedType})
                },
            },
            {
                key: 'geneType',
                type: 'select',
                required: true,
                keyAccessor: (x) => x.id,
                valueAccessor: (x) => x.name,
                options: listGeneType,
                onChange: (geneType) => {
                    setFieldsValue({geneType})
                },
            },
            {
                key: 'breed',
                type: 'select',
                required: true,
                keyAccessor: (x) => x.id,
                valueAccessor: (x) => x.name,
                options: listBreed,
                onChange: (breed) => {
                    setFieldsValue({breed})
                },
            },
        ],
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
            md: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
            md: {span: 12},
        },
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8},
        },
    }
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={subInputTypes1}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                />

                <Form.Item {...submitFormLayout}>
                    <Button loader={load} type="primary" htmlType="submit" style={{marginRight: "18px"}}>
                        <Icon type="download"/> {load ? 'Wait..' : 'Download'}
                    </Button>
                    <Button htmlType="reset" onClick={() => props.dispatch(goBack())}>
                        Back
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

const WrappedAnnouncement = Form.create()(DownloadForm)
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}
export default connect(null, mapDispatchToProps)(WrappedAnnouncement)
