import React, { useEffect, useState } from 'react'
import { Input, Modal, Form, Card, notification } from 'antd'
import Request ,{ API_URL } from '../../../../request'
import _ from 'lodash'

import { FormUtils as GetAllFormFields } from 'sz-react-utils'

const ChangePassword = (props) => {
  const { visible, onCancel, form,user } = props
  const { getFieldDecorator } = form
  const [confirmDirty, setConfirmDirty] = useState(false)

  const handleSubmit = (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = { password: valData.password,id:user.id }

        console.log(values)

        let x = await Request.updateUser(values);

        if (!x.error) {
          notification.success({
            message: x.message,
          })
          onCancel()
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  const handleConfirmBlur = (e) => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!')
    } else {
      callback()
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  return (
    <Modal
      visible={visible}
      title="Update Password"
      okText="Update"
      onCancel={onCancel}
      onOk={handleSubmit}>
      <Card bordered={true}>
        <Form
          onSubmit={handleSubmit}
          hideRequiredMark={false}
          style={{ marginTop: 8 }}>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={handleConfirmBlur} />)}
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  )
}

const WrappedAnnouncement = Form.create()(ChangePassword)

export default WrappedAnnouncement
