import { apiUrl } from "../../../settings";

export const GeneType = () => {
  return apiUrl + `/geneType/`;
};

export const  Breed = () => {
  return apiUrl + `/breed/`;
};

// export const getAllDnaType = () => {
//   return apiUrl + `/company/update/billingType`;
// };

// export const getAllBreed = () => {
//   return apiUrl + `/company/update/billingType`;
// };


