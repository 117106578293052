import React from 'react'
import {  Modal, Form, Card, notification } from 'antd'
import {DisapproveFile } from "../../actions"
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { API_URL } from '../../../../request'
import _ from 'lodash'

const DisApproveComment = (props) => {
  const { visible, onCancel, form,speciesType,breedType,geneId,breedId,userId }= props

 //const {speciesType,breedType,geneId,breed}=record;

  const { getFieldDecorator } = form

  const handleSubmit = (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = { remark: valData.remark,
          speciesType,
          breedType,
          geneId,
          breedId,
          userId }

        let x = await DisapproveFile(values)

        if (!x) {
          notification.success({
            message: x.message,
          })
          onCancel()
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  const inputTypes = {
    fields: [
      {
        label: 'Remark',
        key: 'remark',
        required: true,
        type:"textArea",
        customProps: {
          row:"6",
          maxLength:80
        }
      },
    ],
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 },
    },
  }

  return (
    <Modal
      visible={visible}
      title="Add Remark"
      okText="Disapprove"
      onCancel={onCancel}
      onOk={handleSubmit}>
      <Card bordered={true}>
        <Form
          onSubmit={handleSubmit}
          hideRequiredMark={false}
          style={{ marginTop: 8 }}>
          <GetAllFormFields
            apiurl={API_URL}
            inputSchema={inputTypes}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
        </Form>
      </Card>
    </Modal>
  )
}

const WrappedAnnouncement = Form.create()(DisApproveComment)

export default WrappedAnnouncement
