  import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card } from 'antd'
import { goBack} from 'connected-react-router'

import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { API_URL } from '../../../request'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'

@Form.create()
class AddUser extends PureComponent {
  handleSubmit = (e) => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        dispatch(showLoader())

        let x = null
        if (this.state.id) {
          values.id = this.state.id

          x = await Request.updateUser(values)
        } else {
          x = await Request.addUser(values)
        }

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: this.state.id ? x.message : x.message,
          })

          if (!this.state.id) {
            this.props.form.resetFields()
          }else {
            dispatch(goBack())
          }
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)
    let userId = searchParams.get('id')

    if (userId) {
      this.setState({ id: userId })
      let { data } = await Request.getUserById(userId)
      this.props.form.setFieldsValue({
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        userType: data.userType,
      })
    }
  }

  render() {
    let {} = this.state

    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    const inputTypes = {
      fields: [
        {
          label: 'Name',
          key: 'name',
          required: true,
        },
        {
          label: 'Email',
          key: 'email',
          required: true,
        },
        {
          label: 'Password',
          key: 'password',
          type: 'password',
          required: !this.state.id,
          customProps: {
            disabled: !!this.state.id,
          },
        },
        {
          label: 'Mobile',
          key: 'mobile',
          required: true,
        },
        
        {
          label: 'User Type',
          key: 'userType',
          required: true,
          type: 'select',
          options: ['Admin', 'User'],
          onChange: (v) => {
            this.props.form.setFieldsValue({ userType: v })
          },
        },
      ],
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
      },
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 },
      },
    }

    return (
      <PageHeaderWrapper title={this.state.id ? 'Edit User' : 'Add User'}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark={false}
            style={{ marginTop: 8 }}>
            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={inputTypes}
              formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator}
            />

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.props.loading}>
                {this.state.id ? 'Update' : 'Save'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
