import React, { useEffect, useState } from 'react'
import { Input, Modal, Form, Card, notification } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import Request, { API_URL } from '../../../../request'
import _ from 'lodash'

const ChangePassword = (props) => {
  const { visible, onCancel, form, user, } = props
  const { getFieldDecorator } = form

  const handleSubmit = (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = { status: valData.status, id: user.id }

        let x = await Request.updateUser(values)

        if (!x.error) {
          notification.success({
            message: x.message,
          })
          onCancel()
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  const inputTypes = {
    fields: [
      {
        label: 'Status',
        key: 'status',
        required: true,
        type: 'select',
        options: ['Active', 'Inactive'],
        onChange: (v) => {
          form.setFieldsValue({ status: v })
        },
      },
    ],
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 },
    },
  }

  return (
    <Modal
      visible={visible}
      title="Update Status"
      okText="Update"
      onCancel={onCancel}
      onOk={handleSubmit}>
      <Card bordered={true}>
        <Form
          onSubmit={handleSubmit}
          hideRequiredMark={false}
          style={{ marginTop: 8 }}>
          <GetAllFormFields
            apiurl={API_URL}
            inputSchema={inputTypes}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
        </Form>
      </Card>
    </Modal>
  )
}

const WrappedAnnouncement = Form.create()(ChangePassword)

export default WrappedAnnouncement
