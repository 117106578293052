import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, Upload, Icon } from 'antd'
import {
  ListBreed,
  ListGeneType,
  AddDna
} from './../actions'
import { notification } from 'antd/lib/index'
import { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
//  import { ChangeBillingType } from '../action'
import _ from 'lodash'
import { apiUrl, specArr } from '../../../settings'

const BillingSetting = (props) => {
  const [load, setLoad] = useState(false)
  const [uploading, setuploading] = useState(false)
  const [uploaded, setUploaded] = useState([])
  const [listBreed, setlistBreed] = useState([])
  const [listGeneType, setlistGeneType] = useState([])


  // useEffect(async () => {
  //   // getCompanyBillingType()
  //   let listGeneType = (await ListGeneType()) || []
  //   setlistGeneType(listGeneType)
  // }, [])

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()

    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err && uploaded.length != 0) {

        if (!valData.breed) {
          notification.warning({
            message: 'Choose Breed'
          })
          return
        }
        if (!valData.breedType) {
          notification.warning({
            message: 'Choose Breed Type'
          })
          return
        }
        if (!valData.geneType) {
          notification.warning({
            message: 'Choose Gene Type'
          })
          return
        }
        if (!valData.speciesType) {
          notification.warning({
            message: 'Choose Species Type'
          })
          return
        }

        delete valData.file
        valData.fasFile = uploaded

        if (localStorage.getItem('user')) {
          valData.createdByUserId = JSON.parse(localStorage.getItem('user')).id
        }



        /* const formData = new FormData()
         Object.keys(valData).forEach((key) => {
             formData.append(key, valData[key])
         })
         formData.append('fasFile', uploaded)
         // let user = JSON.parse(currentUser);
         formData.append('user', localStorage.getItem('user'))
         formData.file;
         await AddDna(formData)
         form.resetFields()*/

          setLoad(true)
        await AddDna(valData)
        setLoad(false)
        form.resetFields()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
        setLoad(false)
      }
    })
  }

  async function questionRemove() {
    setUploaded([])
  }

  const uploadProps = {
    //fieldname
    name: 'file',
    // fileList:{uploaded},

    beforeUpload: async (file) => {

      console.log('before', file)
      // const fileReader = new FileReader();
      // console.log(fileReader.readAsText(file))

      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        const result = event.target.result
        if (result) {
          setUploaded(JSON.stringify(result))
        }

      })


      reader.readAsText(file)

      //  reader.readAsDataURL(file);
      // setUploaded([file])

      return true
    },
    // action: "",
    onRemove: questionRemove,
    onchange: (info) => {


      // setUploaded([info.file])


    }
  }

  const {
    form: { getFieldDecorator, setFieldsValue }
  } = props

  const subInputTypes1 = {
    fields: [
      {
        key: 'speciesType',
        type: 'select',
        options: specArr,
        onChange: async (speciesType) => {
          setFieldsValue({ speciesType })
          let listBreed = (await ListBreed({ speciesType })) || []
          let listGeneType = (await ListGeneType({ speciesType })) || []
          setFieldsValue({ breed: null, geneType: null })
          setlistBreed(listBreed)
          setlistGeneType(listGeneType)

        }
      },
      {
        key: 'breedType',
        type: 'select',
        label: 'DNA Type',
        options: ['ALLELIC', 'MIXED'],
        onChange: (breedType) => {
          setFieldsValue({ breedType })
        }
      },
      {
        key: 'geneType',
        type: 'select',
        keyAccessor: (x) => x.id,
        valueAccessor: (x) => x.name,
        options: listGeneType,
        onChange: (geneType) => {
          setFieldsValue({ geneType })
        }
      },
      {
        key: 'breed',
        type: 'select',
        keyAccessor: (x) => x.id,
        valueAccessor: (x) => x.name,
        options: listBreed,
        onChange: (breed) => {
          setFieldsValue({ breed })
        }
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }

  return (
    <PageHeaderWrapper title={'Upload New DNA'}>
      <Card>
        <Form onSubmit={handleSubmit}>
          <GetAllFormFields
            apiurl={API_URL}
            inputSchema={subInputTypes1}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
          <Form.Item label={'Upload New DNA'} {...formItemLayout}>
            {getFieldDecorator('file')(
              <Upload {...uploadProps}>
                <Button>
                  <Icon type='upload' /> Select File
                </Button>
              </Upload>
            )}
          </Form.Item>

          <Form.Item {...submitFormLayout}>
            <Button loader={load} type='primary' htmlType='submit'>
              {load ? 'Wait..' : 'SAVE'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageHeaderWrapper>
  )
}

const WrappedAnnouncement = Form.create()(BillingSetting)

export default WrappedAnnouncement
