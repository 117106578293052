import { apiUrl } from "../../../settings";

export const GeneType = () => {
  return apiUrl + `/geneType`;
};
export const Dna = () => {
  return apiUrl + `/dna`;
};

export const Breed = () => {
  return apiUrl + `/breed`;
};

export const User = () => {
  return apiUrl + `/user`;
};

export const Upload = ()=>{
  return apiUrl + '/upload'
}
export const RemoveUpload = ()=>{
  return apiUrl + '/removeUpload'
}

// export const getAllDnaType = () => {
//   return apiUrl + `/company/update/billingType`;
// };

// export const getAllBreed = () => {
//   return apiUrl + `/company/update/billingType`;
// };


