import React, {useEffect, useState, useRef} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Input,
    Card,
    Row,
    Col,
    Tooltip,
    Drawer,
    Popconfirm,
    Tag,
    Select, notification
} from 'antd'
import {
    FetchFileByType,
    FetchFile,
    ApproveFile,
    deleteDnaFile,
    ListGeneType,
    ListBreed,
} from '../actions'
import DisApproveRemark from './components/DisApproveRemark'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import Request from '../../../request'

const {Option} = Select

export const List = () => {
    const {TextArea} = Input
    const [breedId, setBreedId] = useState()
    const [selectedRecord, setSelectedReocord] = useState(null)
    const [load, setLoad] = useState(false)
    const [userList, setUserList] = useState(false)
    const [deleteLoad, setdeleteLoad] = useState(false)
    const [disApproveVisible, setDisApproveVisible] = useState(false)
    const [dna, setDna] = useState([])
    const [count, setCount] = useState([])
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [dnaCount, setDnaCount] = useState(0)
    const [selectedBreed, setSelectedBreed] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [selectedGene, setSelectedGene] = useState()
    const [selectedBreedType, setSelectedBreedType] = useState()
    const [selectedSpeciesType, setSelectedSpeciesType] = useState()
    const [listBreed, setlistBreed] = useState([])
    const [listGeneType, setlistGeneType] = useState([])
    const table = useRef(null)


    useEffect(async () => {
        // let tmplistGeneType = (await ListGeneType()) || []
        let tmpuserList =
            (await Request.userList({
                regExFilters: [],
                userType: 'User',
                status: 'Active',
            })) || []

        // setlistGeneType(tmplistGeneType)
        setUserList(tmpuserList)
    }, [])

    useEffect(async () => {
        if (!selectedSpeciesType) return

        let tmplistGeneType = (await ListGeneType({speciesType: selectedSpeciesType})) || []
        setlistGeneType(tmplistGeneType)
    }, [selectedSpeciesType])

    useEffect(async () => {
        if (!selectedSpeciesType) return
        let tmplistBreed =
            (await ListBreed({speciesType: selectedSpeciesType})) || []
        setlistBreed(tmplistBreed)
    }, [selectedSpeciesType])

    useEffect(() => {
        table.current.reload()
    }, [selectedSpeciesType, selectedBreed, selectedBreedType, selectedGene])

    const removeFilter = async () => {
        setSelectedBreedType()
        setSelectedBreed()
        setSelectedGene()
        setSelectedUser()
        setSelectedSpeciesType()
        setlistGeneType([])
        setlistBreed([])
        table.current.reload()
    }

    const fetchData=async(breedId)=>{
        delete breedId.dnaContent;
        delete breedId.createdAt;
        delete breedId.updatedAt;
        setDna(null)
        setDrawerVisible(true)
        if (!breedId) return
        const data = await FetchFile(breedId)
        setDna(data)
        setDnaCount(data.total)
    }

    const deleteFile = async (arr) => {

        setdeleteLoad(true)
        await deleteDnaFile({ arr })
        setdeleteLoad(false)
        setDrawerVisible(false);
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const findCount = (record) => {
        let obj = count.find((ele) => {
            return (
                ele.speciesType === record.speciesType &&
                ele.breedType === record.breedType &&
                ele.geneType === record.geneId &&
                ele.breed === record.breedId
            )
        })
        return obj ? obj.count : ''
    }

    const apiRequest = (params) => {
        if (params.page) {
            setCurrentPage(params.page)
        }
        const currentUser = JSON.parse(localStorage.getItem('user'))

        return new Promise(async (resolve) => {
            let tt = await FetchFileByType({
                type: 'unapproved',
                userId: currentUser.userType === 'Admin' ? 0 : currentUser.id,
                speciesType: selectedSpeciesType,
                breedType: selectedBreedType,
                geneType: selectedGene,
                breed: selectedBreed,
            })
            setCount(tt.total)
            resolve(tt)
        })
    }

    const generateActions = (text, record, key) => {
        const currentUser = JSON.parse(localStorage.getItem('user'))

        if (currentUser.userType === 'Admin') {
            return (
                <>
                    <Tooltip title="Approve File">
                        <Popconfirm
                            title="Do you want to approve this file?"
                            onConfirm={async () => {
                                setLoad(true)
                                await ApproveFile(record)
                                setLoad(false)
                            }}
                            onCancel={() => {
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button
                                shape="circle"
                                size="small"
                                loading={load}
                                style={{marginRight: 6}}
                                icon="check"
                            />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title="Disapprove File">
                        <Popconfirm
                            title="Are you sure to disapprove this File?"
                            onConfirm={() => {
                                setDisApproveVisible(true)
                                setSelectedReocord(record)


                            }}
                            onCancel={() => {
                                console.log()
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button
                                shape="circle"
                                size="small"
                                icon="exclamation-circle"
                                loading={deleteLoad}
                            />
                        </Popconfirm>
                    </Tooltip>


                </>
            )
        } else
            return null

    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 100,
            key: 'sno',
            render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
        },
        {
            title: 'Species Type',
            dataIndex: 'speciesType',
            key: 'speciesType',
            searchTextName: 'speciesType',
        },
        {
            title: 'DNA Type',
            dataIndex: 'breedType',
            key: 'breedType',
            searchTextName: 'breedType',
        },

        {
            title: 'Gene Type',
            dataIndex: 'geneType',
            key: 'geneType',
            searchTextName: 'geneType',
        },
        {
            title: 'Breed',
            dataIndex: 'breed',
            key: 'breed',
            searchTextName: 'breed',
        },
        {
            title: 'Created By',
            dataIndex: 'createdByUserId',
            key: 'createdByUserId',
            searchTextName: 'createdByUserId',
        },
        {
            title: 'Created At',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (text, record) => {
                return <Tag>{text ? moment(text).fromNow() : ''}</Tag>
            },
        },
        {
            title: 'Updated At',
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            render: (text, record) => {
                return <Tag>{text ? moment(text).fromNow() : ''}</Tag>
            },
        },
        {
            key: 'toalDna',
            title: 'Total DNAs',
            render: (text, record, key) => {
                return (
                    <>
                        <Tooltip title="View File">
                            <Button
                                type="warning"
                                size="small"
                                style={{marginTop: '6px'}}
                                onClick={() => fetchData(record)}>
                                {findCount(record)}
                            </Button>
                        </Tooltip>
                    </>
                )
            },
        },

        {
            key: 'actions',
            title: 'Actions',
            width: 120,
            render: generateActions,
        },
    ]

    return (
        <>
            <PageHeaderWrapper title={`All Unapproved File (${count.length})`}>
                <Card style={{marginBottom: '20px'}}>
                    <h1>Choose Filters :</h1>
                    <Row type="flex" justify={'space-around'}>
                        <Col span={4}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{width: '100%'}}
                                value={selectedSpeciesType}
                                placeholder={'Search Species'}
                                onChange={(id) => {
                                    setSelectedSpeciesType(id)
                                    setSelectedBreed('')
                                    setSelectedGene('')
                                    if (!id) {
                                        setlistGeneType([])
                                        setlistBreed([])
                                    }
                                }}>
                                <Option value={'Camel'}>{'CAMEL'}</Option>
                                <Option value={'Goat'}>{'GOAT'}</Option>
                                <Option value={'Sheep'}>{'SHEEP'}</Option>
                            </Select>
                        </Col>

                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedBreedType}
                                // onSelect={this.onContactSelect}
                                // onSearch={this.searchContact}
                                onChange={(val) => {
                                    setSelectedBreedType(val)
                                }}
                                placeholder={'Search DNA Type'}
                                style={{width: '100%'}}>
                                <Option value={'Allelic'}>{'ALLELIC'}</Option>
                                <Option value={'Mixed'}>{'MIXED'}</Option>
                            </Select>
                        </Col>

                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedGene}
                                // onSelect={this.onContactSelect}
                                // onSearch={this.searchContact}
                                onChange={(val) => {
                                    setSelectedGene(val)
                                }}
                                placeholder={'Search Gene'}
                                style={{width: '100%'}}>
                                {listGeneType &&
                                listGeneType.map((val, item) => (
                                    <Option key={val.id} value={val.id}>
                                        <>
                                            <div>{`${val.name}`}</div>
                                        </>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select
                                allowClear
                                value={selectedBreed}
                                onChange={(val) => {
                                    setSelectedBreed(val)
                                }}
                                placeholder={'Search Breed'}
                                style={{width: '100%'}}>
                                {listBreed &&
                                listBreed.map((val, item) => (
                                    <Option key={val.id} value={val.id}>
                                        <>
                                            <div>{`${val.name}`}</div>
                                        </>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        {JSON.parse(localStorage.getItem('user')).userType === 'Admin' ? (
                            <Col span={4}>
                                <Select
                                    value={selectedUser}
                                    onChange={(val) => {
                                        setSelectedUser(val)
                                    }}
                                    placeholder={'Search User'}
                                    style={{width: '100%'}}>
                                    {userList &&
                                    userList.data &&
                                    userList.data.map((val, item) => (
                                        <Option key={val.id} value={val.id}>
                                            <>
                                                <div>{`${val.name}`}</div>
                                            </>
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        ) : (
                            ''
                        )}
                        <Col>
                            <Button type="default" onClick={removeFilter}>
                                Clear Filters
                            </Button>
                        </Col>
                    </Row>
                </Card>

                <DisApproveRemark
                    visible={disApproveVisible}
                    speciesType={selectedRecord && selectedRecord.speciesType}
                    breedType={selectedRecord && selectedRecord.breedType}
                    geneId={selectedRecord && selectedRecord.geneId}
                    breedId={selectedRecord && selectedRecord.breedId}
                    userId={selectedRecord && selectedRecord.userId}
                    onCancel={() => setDisApproveVisible(false)}
                />
                <Card bordered={true}>
                    <Row>
                        <Col>
                            <TableComp
                                bordered={true}
                                ref={table}
                                columns={columns}
                                apiRequest={apiRequest}
                                rowKey={(x) => x.id}
                                pagination={{
                                    defaultPageSize: 10,
                                    pageSizeOptions: ['10', '25', '50', '100'],
                                    showSizeChanger: true,
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
                <Drawer
                    title={`All DNAs (${dnaCount})`}
                    placement="right"
                    width={'50%'}
                    closable={true}
                    onClose={() => {
                        setDrawerVisible(false)
                    }}
                    // visible={this.state.drawerVisible}>
                    visible={drawerVisible}>
                    <Card bordered={true}>
                        <div>
                            <Row>
                                <Col span={16}>
                                    {dna &&
                                    `${dna.speciesType} --> ${dna.breedType} --> ${dna.geneType} --> ${dna.breed}`}
                                </Col>

                               {/* <Col col={8}>{dna && `By - ${dna.createdByUserId}`}</Col>*/}

                                <Col span={8}>
                                    <Tooltip title="Delete File">
                                        <Popconfirm
                                          title="Are you sure to Delete this File?"
                                          onConfirm={async () => {


                                              await deleteFile(dna.dnaIds)

                                          }}
                                          onCancel={() => {
                                              console.log()
                                          }}
                                          okText="Yes"
                                          cancelText="No">
                                            <Button
                                              // shape="circle"
                                              size="small"
                                              type={'danger'}

                                                 loading={deleteLoad}
                                            >Delete DNAs</Button>
                                        </Popconfirm>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>
                        <br/>

                        {dna && (
                            <Col span={24}>
                                <TextArea rows={50} defaultValue={dna.dnaContent}/>
                            </Col>
                        )}
                    </Card>
                </Drawer>
            </PageHeaderWrapper>
        </>
    )
}

const WrappedAnnouncement = Form.create()(List)

export default WrappedAnnouncement
