import React from 'react'
import DownloadForm from '../dnaSeq/views/components/downloadForm'
import { Row, Col } from 'antd'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'

export const Download = () => {
  return (
    <>
      <Row type="flex" justify="space-around" style={{ paddingTop: '5%' }}>
        <Col span={16} className="centre largetext">
          <PageHeaderWrapper title={'Download Data'}>

            <Col >
              <DownloadForm />
            </Col>
          </PageHeaderWrapper>
        </Col>
      </Row>
    </>
  )
}
