import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import AddUser from './containers/user/add'
import UserList from './containers/user/all'
import AddDnaSeq from './containers/dnaSeq/views/add'
import DownloadDnaSeq from './containers/dnaSeq/views/download'
import AddGeneType from './containers/setting/view/AddGeneType'
import AddBreed from './containers/setting/view/AddBreed'
import ApprovedList from './containers/listFile/views/ApprovedList'
import UnApprovedList from './containers/listFile/views/unApprovedList'
import RejectList from './containers/listFile/views/RejectedList'
import Backup from './containers/backup/backupData'

const menu = [
    // {
    //   path: '/dashboard',
    //   name: 'Dashboard',
    //   icon: 'dashboard',
    //   key: 'dashboard',
    //   title: 'Dashboard',
    //   homepage: true,
    //   component: Dashboard,
    //   authority: ['Admin','user'],
    // },
    {
        path: '/user',
        name: 'User',
        title: 'User',
        key: 'user',
        authority: ['Admin'],
        children: [
            {
                path: '/user/add',
                name: 'Add User',
                title: 'Add User',
                component: AddUser,
            },
            {
                key: 'editUser',
                dontShowOnMenu: true,
                path: '/user/edit',
                name: 'Edit User',
                title: 'Edit User',
                component: AddUser,
            },
            {
                path: '/user/list',
                name: 'User List',
                title: 'User List',
                component: UserList,
            },
        ],
    },
    //  {
    //     'path': '/dnaseq',
    //     'name': 'Dnaseq',
    //     'title': 'Dnaseq',
    //     'key': 'Dnaseq',
    //     'children': [

    //     ]
    // },
    {
        path: '/dnaseq/add',
        name: 'Upload DNAs',
        title: 'Upload DNAs',
        component: AddDnaSeq,
        authority: ['User'],
    },
    // {
    //   path: '/dnaseq/download',
    //   name: 'Download File',
    //   title: 'Download File',
    //   component: DownloadDnaSeq,
    // },
    {
        path: '/dnaseq/approved',
        name: 'Approved DNAs',
        title: 'Approved DNAs',
        component: ApprovedList,
    },
    {
        path: '/dnaseq/unapproved',
        name: 'Unapproved DNAs',
        title: 'Unapproved DNAs',
        component: UnApprovedList,
        authority: ['Admin', 'User'],
    },
    {
        path: '/dnaseq/rejected',
        name: 'Rejected DNAs',
        title: 'Rejected DNAs',
        component: RejectList,
        // authority: ['User'],
    },
    {
        path: '/setting/',
        name: 'Settings',
        title: 'Settings',
        authority: ['Admin'],
        children: [
            {
                path: '/setting/addGeneType',
                name: 'Gene type',
                title: 'Gene Type',
                component: AddGeneType,
            },
            {
                path: '/setting/breed',
                name: 'Breed',
                title: 'Breed',
                component: AddBreed,
            },
        ],
    },
    {
        path: '/backup',
        name: 'Backup',
        title: 'Backup',
        authority: ['Admin'],
        component: Backup
    },
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    console.log(getUrlPath(keyString, query))
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, (p) => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, (p) => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = (keyString) => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, (p) => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, (p) => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu
