import {notification} from 'antd'
import axios from 'axios'

import {apiUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

export let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
}

function download(record) {
    fetch(`${apiUrl}/recordingStream?callingTime=${record.callingTime}&duration=${record.duration}&roNumber=${record.roNumber}`, getToken()).then(function (t) {
        let name = t.headers.get('Content-Disposition').split('=')[1].replace(/['"]+/g, '')
        return t.blob().then((b) => {
                var a = document.createElement('a')
                a.href = URL.createObjectURL(b)
                a.setAttribute('download', name)
                a.click()
            }
        )
    })
}

function downloadData(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
                let a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            }
        );
    });
}


class Request {

    error = (err) => {
        try {
            if (err.response.status === 401) {
                console.log("clearing localstorage")
                localStorage.clear()
                window.location.reload()
            }
        } catch (e) {
        }
    }

    constructor() {

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios.post('/login', data)
                .then((d) => {
                    console.log(d, '============')
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addUser(data) {
        return new Promise(next => {
            authAxios
                .post('/user', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateUser(data) {
        return new Promise(next => {
            authAxios
                .put('/user/' + data._id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getUserById(id) {
        return new Promise(next => {
            authAxios
                .get('/user/' + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    userList(data) {
        return new Promise(next => {
            authAxios
                .get('/user', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

    downloadUserBackupData(data) {
        return new Promise((next) => {
            authAxios
                .post('/user/BackupDownload', data)
                .then((d) => {
                    if (d.data) {
                        console.log(d.data.data)
                        downloadData(`${API_URL}/${d.data.data}`, d.data.data)
                    }
                    next('done')
                })
                .catch((err) => {
                    this.error(err)
                })
        })
    }

    downloadBreedBackupData(data) {
        return new Promise((next) => {
            authAxios
                .post('/breed/BackupDownload', data)
                .then((d) => {
                    if (d.data) {
                        downloadData(`${API_URL}/${d.data.data}`, d.data.data)

                    }
                    next('done')
                })
                .catch((err) => {
                    this.error(err)
                })
        })
    }

    downloadDNABackupData(data) {
        return new Promise((next) => {
            authAxios
                .post('/dna/BackupDownload', data)
                .then((d) => {
                    if (d.data) {
                        downloadData(`${API_URL}/${d.data.data}`, d.data.data)

                    }
                    next('done')
                })
                .catch((err) => {
                    this.error(err)
                })
        })
    }

    downloadGeneBackupData(data) {
        return new Promise((next) => {
            authAxios
                .post('/gene/BackupDownload', data)
                .then((d) => {
                    if (d.data) {
                        // let linkValue = `${API_URL}/${d.data.data}`
                        // window.open(linkValue, 'download')
                        downloadData(`${API_URL}/${d.data.data}`, d.data.data)

                    }
                    next('done')
                })
                .catch((err) => {
                    this.error(err)
                })
        })
    }


}


export default new Request()
