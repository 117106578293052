import React, {useEffect, useState, useRef} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Input,
    Card,
    Popconfirm,
    Row,
    Col,
    Select,
    Tooltip,
    AutoComplete, notification, Icon, List
} from 'antd'
import {FetchFileByType, FetchFile, ListBreed, ListGeneType, deleteDnaFile} from '../listFile/actions'
import {TableComp} from 'sz-react-utils'
import Request, {API_URL} from '../../request'

const {Option} = Select

export const Backup = () => {
    const table = useRef(null)
    const {TextArea} = Input
    const [userLoader, setUserLoad] = useState(0)
    const [breedLoader, setBreedLoad] = useState(0)
    const [dnaLoader, setDnaLoad] = useState(0)
    const [geneLoader, setGeneLoad] = useState(0)
    const [showCard, setCard] = useState(0)


    return (
        <>
            <PageHeaderWrapper title={'Backup'}>
                <Card style={{marginBottom: '20px'}}>
                    <Button type={'primary'} onClick={() => setCard(!showCard)}>Create Backup</Button>


                    {showCard ? <Card style={{marginTop: '20px'}}>

                        <Row>
                            <List>
                                <List.Item>
                                    <a onClick={async () => {
                                        await Request.downloadUserBackupData()
                                    }}>
                                        User Backup <Icon style={{marginLeft: '10px'}} type="download"/>
                                    </a>
                                </List.Item>
                                <List.Item>
                                    <a onClick={async () => {
                                        await Request.downloadDNABackupData({isApproved: true,})

                                    }}>
                                        DNA Backup <Icon style={{marginLeft: '10px'}} type="download"/>
                                    </a>
                                </List.Item>
                                <List.Item>
                                    <a onClick={async () => {
                                        await Request.downloadGeneBackupData()

                                    }}> Gene Backup <Icon style={{marginLeft: '10px'}} type="download"/></a>
                                </List.Item>
                                <List.Item>
                                    <a onClick={async () => {
                                        await Request.downloadBreedBackupData()

                                    }}>
                                        Breed Backup <Icon style={{marginLeft: '10px'}} type="download"/>
                                    </a>
                                </List.Item>




                            </List>

                        </Row>

                    </Card> : ''}
                </Card>

            </PageHeaderWrapper>
        </>
    )
}

const WrappedAnnouncement = Form.create()(Backup)

export default WrappedAnnouncement
