import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {getUrlPushWrapper} from '../../../routes'
import {Card, Form, Button, Tooltip, Popconfirm} from 'antd'
import Request from '../../../request'
import {connect} from 'react-redux'
import ChangePassword from './component/changePassword'
import ChangeStatus from './component/changeStatus'

@Form.create()
class AllUsers extends Component {
    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await Request.userList({
                ...params,
                regExFilters: [],
            })
            this.setState({count: data.total})
            console.log(data)
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {changePasswordVisible: false}
    }

    async componentDidMount() {
    }

    render() {
        const {dispatch} = this.props

        let {} = this.state
        const columns = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
                searchTextName: 'name',
            },

            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                searchTextName: 'email',
            },

            {
                title: 'Mobile',
                key: 'mobile',
                dataIndex: 'mobile',
            },
            {
                title: 'User type',
                key: 'userType',
                dataIndex: 'userType',
                searchTextName: 'userType',
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                searchTextName: 'status',
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 150,
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="Edit">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        dispatch(
                                            getUrlPushWrapper('user.editUser', {
                                                id: record.id,
                                            })
                                        )
                                    }}
                                    icon="edit"
                                />
                            </Tooltip>
                            <Tooltip title="Change password">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        this.setState({changePasswordVisible: true, user: record})
                                    }}
                                    icon="credit-card"
                                />
                            </Tooltip>
                            <Tooltip title="Change Status">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        this.setState({changeStatusVisible: true, user: record})
                                    }}
                                    icon="setting"
                                />
                            </Tooltip>
                        </>
                    )
                },
            },
        ]
        return (
            <PageHeaderWrapper title={`All User (${this.state.count || 0})`}>
                <ChangePassword
                    visible={this.state.changePasswordVisible}
                    user={this.state.user}
                    onCancel={() => this.setState({changePasswordVisible: false})}
                />
                <ChangeStatus
                    visible={this.state.changeStatusVisible}
                    user={this.state.user}
                    onCancel={() => {
                        this.setState({changeStatusVisible: false})
                        this.table.current.reload()
                    }}
                />
                <Card bordered={true}>
                    <TableComp
                        ref={this.table}
                        columns={columns}
                        apiRequest={this.apiRequest}
                        pagination={{
                            ...this.state.pagination,
                            defaultPageSize: 50,
                            pageSizeOptions: ['10', '25', '50', '100'],
                            showSizeChanger: true,
                            ...this.props.pagination,
                        }}
                    />
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers)
