export default {}

export const pageTitle = 'BackOffice'
export const subTitle = 'Powered by Scizers.com'


// let mainURL = 'http://localhost:8040'
//let mainURL = 'https://ndriruminants-r.scizers.com'

let mainURL = 'http://api.mhcdbms.in'
// if (process.env.NODE_ENV == 'production') {
//   mainURL = 'https://sales-r.ht-india.com'
// }

export const specArr=["SHEEP","CAMEL","GOAT"]

export const apiUrl = mainURL

