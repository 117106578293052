import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import {HashRouter} from 'react-router-dom'
import './index.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    {/*<ConnectedRouter history={history}>*/}
   <HashRouter >
      <App/>
   </HashRouter>
    {/*</ConnectedRouter>*/}
  </Provider>,
  target
)
