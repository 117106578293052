import React, {Component} from 'react'
import {
    Select,
    DatePicker,
    Card,
    Row,
    Col,
    Icon,
    Spin
} from 'antd'

import 'react-quill/dist/quill.snow.css'
import Request from '../../request'
import styles from './style.less'


class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    async componentDidMount() {

    }


    render() {


        let {} = this.state


        return (
            <div>
                <h1>Dashboard</h1>
            </div>

        )
    }

}

export default Dashboard

